<script setup lang="ts">
import { type IBookBase } from "~/types/book";
import { shorteningNumber } from "~/utils/numbers";
import { useDevice } from "#imports";

interface IProps {
  book: IBookBase,
  showGenre?: boolean
}

const config = useRuntimeConfig()
const { isMobile } = useDevice();

const props = withDefaults(defineProps<IProps>(), {
  showGenre: false
});

const genre = computed(() => props.book.genres
    ? props.book.genres[0]
    : null
);

const isUploadedByUser = computed(() => typeof props.book.uploaded_by === "string");
</script>

<template>
  <div class="flex flex-none flex-col gap-2 max-md:gap-1 items-stretch w-[186px] max-md:w-[116px] relative">
      <div class="rounded-lg overflow-hidden">
        <nuxt-link :to="isUploadedByUser ? `/book/${book.slug}` : `/books/${book.slug}`">
          <BookCover
              :path="book.cover_path"
              :url="book.cover"
              :alt="`Book cover of “${book.title}“ by ${book.author}`"
              loading="lazy"
              :quality="80"
              :width="isMobile ? 124 : 186"
              :height="isMobile ? 173 : 260"
              format="webp"
              ismap=""
              decoding="auto"
          />
        </nuxt-link>
      </div>
    <div class="flex gap-1 flex-col">
      <nuxt-link :to="isUploadedByUser ? `/book/${book.slug}` : `/books/${book.slug}`" :title="book.title" prefetch>
        <h3 class="grow text-lg leading-5 max-md:text-sm font-semibold line-clamp-2">
          {{ book.title }}
        </h3>
      </nuxt-link>
      <nuxt-link :to="`/genres/${book.genres[0].slug}-novels`" v-if="showGenre && book?.genres && book.genres.length > 0" class="text-violet-800 dark:text-violet-500">
        {{ book.genres[0].name }}
      </nuxt-link>
      <div class="!text-gray-500 text-sm">
        {{ shorteningNumber(book.views) }} views
      </div>
    </div>
    <div class="flex flex-col items-start gap-2 absolute lef-0 top-4 text-xs text-white">
      <div v-if="book.is_completed" class="p-1 bg-[#325E3E] rounded-r">Completed</div>
      <div v-if="book.is_recommended" class="p-1 bg-[#6415BE] rounded-r">Recommended</div>
    </div>
    <div class="flex flex-col items-start gap-2 absolute right-0 top-4 text-xs text-white font-semibold">
      <div v-if="book.age_restriction" class="p-1 bg-[#E70073] rounded-l">18+</div>
    </div>
  </div>
</template>

<style scoped>

</style>